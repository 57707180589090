<template>
  <div class="box">
    <div class="total">
      <div class="top">
        <div class="text">概况</div>
        <div class="time" @click="openTime"><span>{{ timeText }}</span><van-icon name="arrow-down" /></div>
      </div>
      <div class="num">
        <div><span>会员数(绑定)</span><span><span>{{ total.memberNum }}</span>人</span></div>
        <div><span>进馆人数</span><span><span>{{ total.indoorNum }}</span>人</span></div>
        <div><span>销售数量</span><span><span>{{ total.saleNum }}</span></span></div>
        <div><span>核销数量</span><span><span>{{ total.writeOffNum }}</span></span></div>
      </div>
      <div class="table-box">
        <div class="table-title">
          <span class="c1"></span>
          <span v-for="(i, index) in listJson" v-if="[0, 12, 13].indexOf(index) == -1">
            <span>{{ i.title }}</span><span v-if="i.unit" class="unit">({{ i.unit }})</span>
          </span>
        </div>
        <div class="table-body iphonex-pb" ref="bodyRef" v-if="total.saleData">
          <div class="table-item" v-for="(i, key) in numList" :key="key">
            <span class="c1">{{ i.name }}</span>
            <span v-for="(i2, index) in listJson" v-if="[0, 12, 13].indexOf(index) == -1">
              {{ total[i.value][i2.param] }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="team" v-if="menuIndex == 2">
      <div class="top">
        <div class="text">按团队</div>
      </div>
      <div class="table-box">
        <div class="table-title">
          <span class="c1"></span>
          <span v-for="i in listJson">
            <span>{{ i.title }}</span><span v-if="i.unit" class="unit">({{ i.unit }})</span>
          </span>
        </div>
        <div class="table-body iphonex-pb" ref="bodyRef" v-if="total.saleData">
          <div class="table-item" v-for="(i, key) in total.teamSaleList" :key="key">
            <span class="c1 ellipsis-1">{{ i.saleTeamName }}</span>
            <span v-for="i2 in listJson">
              {{ total.teamSaleList[key][i2.param] }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="team-user" v-if="menuIndex > 0">
      <div class="top">
        <div class="text">按专员</div>
        <div v-if="menuIndex == 2" class="time" @click="showPicker = true"><span>{{ teamText }}</span><van-icon
            name="arrow-down" /></div>
      </div>
      <div class="table-box">
        <div class="table-title">
          <span class="c1"></span>
          <span v-for="i in listJson">
            <span>{{ i.title }}</span><span v-if="i.unit" class="unit">({{ i.unit }})</span>
          </span>
        </div>
        <div class="table-body iphonex-pb" ref="bodyRef" v-if="total.saleData">
          <div class="table-item" v-for="(i, key) in total.userSaleList" :key="key">
            <span class="c1">{{ i.userName }}</span>
            <span v-for="i2 in listJson">
              {{ total.userSaleList[key][i2.param] }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <choseCalendarVue ref="choseTimeRef" :minDate="minDate" :maxDate="maxDate" @choseTime="choseTimeFun" :hasAll="false"
      :maxRange="31" />
    <van-popup v-model="showPicker" round position="bottom">
      <van-picker show-toolbar :columns="teamList" @cancel="showPicker = false" @confirm="getTeamUserData" />
    </van-popup>
  </div>
</template>

<script>
import choseCalendarVue from "./choseCalendar.vue";
import userMixin from '@/mixin/userMixin';
import { getFormatDate } from '@/lib/utils'
export default {
  components: {
    choseCalendarVue
  },
  mixins: [userMixin],
  props: {
    menuIndex: { type: [Number, String], default: 0 },
    saleTeamId: { type: [Number, String], default: '' }
  },
  data() {
    return {
      minDate: new Date('2025/01/01'),
      maxDate: new Date(),
      timeText: '',
      sourceEnum: ['PERSON', 'TEAM', 'PROJECT'],
      total: {},
      listJson: [
        { title: '销售数量', param: 'totalNum' },
        { title: '超鹿月卡', unit: '月卡', param: 'superDeerCardMonthNum' },
        { title: '超鹿月卡', unit: '季卡', param: 'superDeerCardQuarterNum' },
        { title: '超鹿月卡', unit: '半年卡', param: 'superDeerCardHalfYearNum' },
        { title: '超鹿月卡', unit: '年卡', param: 'superDeerCardYearNum' },
        { title: '私教体验课', unit: '19.9元', param: 'perNineteenNum' },
        { title: '私教体验课', unit: '99元', param: 'perNinetynineNum' },
        { title: '团课钩子', param: 'groupHookNum' },
        { title: '私教课包', param: 'perPackageNum' },
        { title: '包月私教', param: 'perCardNum' },
        { title: '礼品卡', param: 'giftNum' },
        { title: '团课产品', param: 'groupProductNum' },
        { title: '核销数量', param: 'writeOffTotalNum' },
        { title: '核销退款数', param: 'refundTotalNum' },
      ],
      numList: [{ name: '销售数量', value: 'saleData' }, { name: '核销数量', value: 'writeOffData' }, { name: '核销退款数量', value: 'refundData' }],
      teamList: [],
      teamText: '全部',
      showPicker: false,
      tag: true,
    }
  },
  async mounted() {
    const now = new Date();
    let endDate = getFormatDate(now, 'yyyy.MM.dd')
    let startDate = getFormatDate(now.setDate(now.getDate() - 30), 'yyyy.MM.dd')
    this.timeText = (startDate < '2025.01.01' ? '2025.01.01' : startDate) + ' - ' + endDate
  },
  methods: {
    choseTimeFun(v) {
      this.timeText = v.timeText
      this.getTotalData()
    },
    openTime() {
      this.$refs.choseTimeRef.showTime({ chose: this.timeText })
    },
    getTotalData(v) {
      let param = {
        startTime: this.timeText.split('-')[0].replace(/\./ig, '-') + ' 00:00:00',
        endTime: this.timeText.split('-')[1].replace(/\./ig, '-') + ' 23:59:59',
        userId: this.userId,
        sourceEnum: this.sourceEnum[this.menuIndex]
      }
      v ? param.querySaleTeamId = v.id : this.teamText = '全部'
      param.saleTeamId = this.menuIndex == 1 ? this.saleTeamId : ''
      this.$axios.post(this.baseURLApp + "/userExpansionBing/query/projectOverview", param)
        .then((res) => {
          this.total = res.data
          if (this.tag) {
            this.teamList = [{ text: '全部', id: '' }, ...res.data.teamSaleList.map(i => ({ text: i.saleTeamName, id: i.saleTeamId }))]
            this.tag = false
          }
        })
    },
    getTeamUserData(v) {
      this.showPicker = false
      this.teamText = v.text
      this.getTotalData(v)
    }
  }

}
</script>

<style lang="less" scoped>
.box {
  background: #F5F5F5;
  min-height: 76.5vh;

  .total,
  .team,
  .team-user {
    background: #fff;

    .top {
      padding: 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .text {
        font-size: 24px;
        display: flex;
        align-items: center;
        position: relative;
        padding-left: 15px;

        &::after {
          content: '';
          display: inline-block;
          width: 6px;
          height: 22px;
          background: #267DFF;
          position: absolute;
          border-radius: 30px;
          left: 0px;
        }
      }
    }

    .time {
      font-size: 22px;
      line-height: 1.2;
      padding: 13px 17px;
      border: 2px solid #DDDDDD;
      border-radius: 4px;

      span {
        border-right: 1px solid #DDDDDD;
        padding-right: 17px;
        margin-right: 15px;
      }
    }

    .num {
      display: flex;
      justify-content: space-between;
      font-size: 22px;
      padding: 13px 25px 25px;

      >div {
        display: flex;
        flex-direction: column;
        align-items: center;

        >span:last-child {
          margin-top: 20px;

          >span {
            margin-bottom: 14px;
            font-size: 48px;
            font-weight: 600;
            font-family: BebasNeueBold;
          }
        }
      }
    }
  }

  .team,
  .team-user {
    margin-top: 16px;
  }


  .table-box {
    overflow-x: scroll;

    .table-title,
    .table-body .table-item {
      display: flex;
      align-items: center;
      font-size: 24px;
      width: 1700px;

      .c1 {
        width: 170px;
        position: sticky;
        left: 0px;
        background-color: #F6FAFF;
        flex-wrap: nowrap;
        white-space: nowrap;
      }

      span {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 150px;
      }
    }

    .table-title {
      font-weight: 500;
      position: sticky;

      >span {
        background-color: #F6FAFF;
        padding: 0px 20px;
        height: 104px;
        box-sizing: border-box;

        .unit {
          font-size: 20px;
          font-weight: 400;
        }
      }
    }

    .table-body {
      // max-height: 10vh;
      // // overflow-y: scroll;
      .table-item {
        >span {
          height: 88px;
          background-color: #F9FAFB !important;
        }

        &:nth-of-type(odd) {
          >span {
            background-color: #fff !important;
          }
        }
      }
    }
  }
}
</style>