import { render, staticRenderFns } from "./totalList.vue?vue&type=template&id=20a61221&scoped=true&"
import script from "./totalList.vue?vue&type=script&lang=js&"
export * from "./totalList.vue?vue&type=script&lang=js&"
import style0 from "./totalList.vue?vue&type=style&index=0&id=20a61221&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20a61221",
  null
  
)

export default component.exports